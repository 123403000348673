<template>
  <div class="search-content">
    <form class="search-box" action="/">
      <Search
        v-model="text"
        show-action
        autofocus
        placeholder="请输入搜索关键词"
        left-icon=""
        @input="handelSearch"
        @cancel="handelRouteBack"
      />
    </form>

    <!-- 结果列表 -->
    <!-- 列表 -->
    <List
      v-if="resultList.length"
      class="mt-56"
      v-model="loading"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :finished="finished"
      finished-text="没有更多了"
      @load="handelSearch"
    >
      <ul class="list">
        <Scard v-for="item in resultList" :key="item.id" :item="item"> </Scard>
      </ul>
    </List>

    <!-- 缺省页 -->
    <Empty v-else imgWidth="0.32rem" imgHeight="0.32rem" :image="noInfo" description="暂无信息，请重新输入" />
  </div>
</template>

<script>
import { Search, Icon, List } from 'vant';
import { defineComponent, ref, onActivated, watch } from '@vue/composition-api';
import Empty from '@components/Empty/index.vue';
import { useRouter } from '@/utils/compsitionHack';
import useDebounce from '@hook/useDebounce';
import UA from '@/client/ua';
import ClientApp from '@/client/index';
import Scard from '../components/card';
import { getBillSearch } from '@/api/waybill';

export default defineComponent({
  name: 'waybillSearch',
  components: { Search, Empty, Icon, Scard, List },
  setup(_, ctx) {
    const router = useRouter();

    // 改变页面背景颜色
    onActivated(() => {
      document.querySelector('body').setAttribute('style', 'background-color:#f5f5f5');
    });

    // 缺省页图片
    const noInfo = require('@/assets/icon_noInfo.png');
    // 搜索内容
    const text = ref('');
    // 搜索结果列表
    const resultList = ref([]);

    const loading = ref(false);
    const finished = ref(false);
    const error = ref(false);
    const pageNum = ref(1);

    // 改变搜索条件时，清空列表
    watch(text, () => {
      pageNum.value = 1;
      resultList.value = [];
    });

    const handelSearch = useDebounce(async () => {
      if (!text.value) {
        resultList.value = [];
        return;
      }
      // 获取运单列表接口
      const [e, res] = await ctx.root.$await(
        getBillSearch({
          pageNum: pageNum.value,
          pageSize: 10,
          param: text.value
        })
      );
      if (e) {
        // 如果请求失败，显示失败文案
        setTimeout(() => {
          error.value = true;
        }, 1000);
      } else {
        const data = res.data;
        if (data.records.length) {
          resultList.value = resultList.value.concat(data.records);
          pageNum.value++;
        } else {
          // 如果没有数据了，显示完成文案
          setTimeout(() => {
            finished.value = true;
          }, 1000);
        }
      }
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    }, 500);

    // 返回上一页
    const handelRouteBack = () => {
      // 如果是在客户端环境，调用客户端返回方法
      if (UA.androidClient) {
        ClientApp.finish();
      } else {
        router.back();
      }
    };

    return {
      loading,
      error,
      finished,
      pageNum,
      noInfo,
      text,
      resultList,
      handelSearch,
      handelRouteBack,
      useDebounce
    };
  }
});
</script>
<style lang="less" scoped>
.search-content {
  overflow: hidden;
  .search-box {
    position: fixed;
    z-index: 99999;
    width: 100%;
  }
  .list {
    padding: 0 0.16rem;
  }
}
</style>
<style lang="less">
.highlights-text {
  color: #1679ff;
}
.van-search__content {
  border: 0.01rem solid #bcbcbc;
  height: 0.3rem;
  border-radius: 0.06rem;
}
.van-search {
  padding: 0.07rem 0 0.07rem 0.12rem;
  .van-cell {
    padding: 0.05rem 0;
  }
  .van-field__body {
    height: 100%;
  }
  .van-field__control {
    height: 100%;
    width: 90%;
    font-weight: 600;
  }
  .van-icon-search {
    font-size: 0.2rem;
    color: #999;
  }
  .van-icon-clear {
    font-size: 0.15rem;
    color: #1679ff;
    padding-right: 0.16rem;
  }
  .van-search__action {
    color: #1677ff;
    font-size: 0.16rem;
    padding: 0 0.12rem;
    font-weight: 600;
  }
}
</style>
